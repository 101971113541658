interface DictItem {
  dictValue: number;
  dictName: string;
}

// 用户类型
export const UserTypeDict: DictItem[] = [
  {
    dictValue: 1,
    dictName: '普通用户',
  },
  {
    dictValue: 2,
    dictName: '分销商',
  },
];

// 审核选项
export const AuditOptionsDict: DictItem[] = [
  {
    dictName: '通过',
    dictValue: 1,
  },
  {
    dictName: '未通过',
    dictValue: 2,
  },
];

// 审核状态
export const AuditStatusDict: DictItem[] = [
  {
    dictName: '待审核',
    dictValue: 0,
  },
  {
    dictName: '已通过',
    dictValue: 1,
  },
  {
    dictName: '未通过',
    dictValue: 2,
  },
];

// 提现渠道
export const withdrawalSourceDict: DictItem[] = [
  {
    dictName: '微信小程序',
    dictValue: 1,
  },
  {
    dictName: 'H5',
    dictValue: 2,
  },
];
