import React from 'react';
import { action, observable } from 'mobx';
import { request } from '../../../utils';
import type { FormInstance } from 'antd';
import type { BaseData } from '../../../utils';
import { API } from '../api';
import { message } from 'antd';

export default class EditUserIdentityModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible: boolean;

  @observable public parent: any;

  @observable public userId: string;

  @observable public saveLoading = false;

  public formRef = React.createRef<FormInstance>();

  @action public onShow = (row: any) => {
    if (row) {
      this.userId = row?.id;
      setTimeout(() => {
        this.formRef?.current?.setFieldsValue(row);
      }, 0);
      this.visible = true;
    }
  };

  @action public onCancel = () => {
    this.visible = false;
  };

  @action public onSubmit = () => {
    this.formRef.current.validateFields().then((values) => {
      this.saveLoading = true;
      request<BaseData<any>>({
        url: API.editUserIdentity,
        method: 'POST',
        data: {
          id: this.userId,
          ...values,
        },
      })
        .then((res) => {
          this.saveLoading = false;
          this.parent?.pageStore?.grid?.onQuery();
          this.onCancel();
          message.success('操作成功！');
        })
        .catch((err) => {
          this.saveLoading = false;
          Promise.reject(err);
        });
    });
  };
}
