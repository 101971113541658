import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { SearchListStructure } from '../../utils/searchListStructure';
import Model from './model';
import { UserDetail } from './modal/userDetail';
import { UserIdentity } from './modal/userIdentity';

const store = new Model();
const UserManagement = observer(() => {
  const { pageStore, UserDetailStore, UserIdentityStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <UserDetail store={UserDetailStore} />
      <UserIdentity store={UserIdentityStore} />
    </div>
  );
});

export default UserManagement;
