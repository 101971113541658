export const API = {
  /* 查询用户列表 */
  fetchUserList: '/quan/biz_user/manage/user/list',
  /* 封禁用户 */
  freezeUser: '/quan/biz_user/manage/user/freeze',
  /* 需求类型列表 */
  fetchTargetListPage: '/quan/biz/target/list/page',
  /* 编辑用户身份 */
  editUserIdentity: '/quan/biz_user/manage/updateUser',
};
