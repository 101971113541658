import { observer } from 'mobx-react';
import type Model from './userIdentityModel';
import React from 'react';
import { Modal, Select, Form } from 'antd';

const { Option } = Select;
export const UserIdentity = observer(({ store }: { store: Model }) => {
  const { visible, formRef, saveLoading, onSubmit, onCancel } = store;
  return (
    <Modal
      title="编辑用户身份"
      centered
      maskClosable={false}
      onOk={onSubmit}
      onCancel={onCancel}
      open={visible}
      confirmLoading={saveLoading}
    >
      <div style={{ padding: '20px 0' }}>
        <Form
          ref={formRef}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="userType"
            label="选择身份"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="请选择用户身份"
              style={{ width: '100%' }}
            >
              <Option value={1}>普通用户</Option>
              <Option value={2}>分销商</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
});
